import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import {
  IFixed,
  IFluid,
  ILocalFile
  , IImageMeta
} from "../types/local-files";
import {
  FullWidthColumnWrapper,
  MediumColumnWidthWrapper,
  SmallColumnWidthWrapper
} from "../components/confines-wrappers";
import { IFieldTechnicalArticle } from "../components/field-technical-article/field-technical-article";
import Footer from "../components/footer";
import FullWidthTeaser from "../components/full-width-teaser";
import { ISimpleTag } from "../components/full-width-teaser/full-width-teaser";
import GetInTouch from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HeroTags from "../components/hero-tags";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import Icon from "../components/icon";
import InsightsTeaser from "../components/insights-teaser";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import Pagination from "../components/pagination";
import Wysiwyg from "../components/wysiwyg";
import getProfile from "../templates/utils/getProfile";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";
import { IProfileData } from "./about-us";

export interface IInsightsArticleData {
  title: string;
  id: string;
  created: string;
  changed: string;
  field_meta_description: string;
  path: {
    alias: string;
  };
  field_approx_reading_time: string;
  field_cover_image: IImageMeta;
  field_labs_main_summary: {
    processed: string;
  };
  field_labs_cover_summary: {
    processed: string;
  };
  relationships: {
    field_technical_article: IFieldTechnicalArticle[];
    field_labs_tags: ISimpleTag[];
    author: IProfileData;
    field_meta_image?: ILocalFile<IFixed>;
    field_cover_image: ILocalFile<IFluid>;
    field_hero_image?: ILocalFile<IFixed>;
    field_mobile_hero_image?: ILocalFile<IFixed>;
    field_related_labs_projects: Array<{
      created: string;
      title: string;
      path: {
        alias: string;
      };
      relationships: {
        field_labs_tags?: ISimpleTag[];
        author: IProfileData;
        field_cover_image: ILocalFile<IFluid>;
      };
    }>;
  };
}

interface INodeData {
  node: IInsightsArticleData;
}

export interface ITaxonomyTermInsights {
  node: {
    name: string;
    path: {
      alias: string;
    };
  };
}

interface IInsightsProps {
  totalInsights: {
    totalCount: number;
  };
  nodeLabsOverview: {
    changed: string;
    title: string;
    field_intro_text: string;
    field_meta_description: string;
    relationships: {
      field_featured_labs_article: IInsightsArticleData;
    };
  };
  allNodeLabsArticle: {
    edges: INodeData[];
  };
  allTaxonomyTermLabs: {
    edges: ITaxonomyTermInsights[];
  };
}

export const PageTopCustom = styled.div`
  background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "lightestGrey"
    })};

  .top-content {
    transition: 0.2s ease-in-out;
    padding-top: 10%;
    padding-bottom: 10%;

    .content {
      position: relative;

      .heading-container {
        position: relative;
        transition: 0.2s ease-in-out;

        .heading,
        h2,
        .into-text {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        }

        .heading,
        h2 {
          transition: 0.2s ease-in-out;
          margin: 0;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .into-text {
          margin: 0;
          padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
        }

        .icon {
          display: none !important;
          fill: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content {
      .content {
        .heading-container {
          .heading,
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .top-content {
      .content {
        .heading-container {
          padding-left: 5.4rem;

          .into-text {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          }

          .icon {
            display: inline-block !important;
            position: absolute;
            top: -2rem;
            left: 0;
          }
        }

        .hero-tags-container {
          margin-left: 2rem;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content {
      padding-top: 8.8rem;
      padding-bottom: 8.8rem;
    }
  }

  @media (min-width: 960px) {
    .top-content {
      .content {
        .heading-container {
          max-width: 98rem;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content {
      .content {
        .heading-container {
          max-width: 112rem;

          .into-text {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }
        }
      }
    }
  }
`;

export const InsightsTeasersContainer = styled.div`
  padding-top: 0;

  .insights-teasers-wrapper {
    transition: 0.2s ease-in-out;
    position: relative;
    margin-top: 16%;
    padding-top: 16%;

    &:first-child {
      margin-top: 0;
      border-top: 0;

      .hr {
        display: none;
      }
    }

    .hr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      span {
        display: block;
        height: 0.2rem;
        background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "lightestGrey"
    })};
      }
    }
  }

  .featured-article-container + .insights-teasers-wrapper {
    padding-top: 0;

    .hr {
      display: none;
    }
  }

  @media (min-width: 568px) {
    .insights-teasers-wrapper {
      margin-top: 8.8rem;
      padding-top: 8.8rem;
    }
  }
`;

const Insights = ({ data }: { data: IInsightsProps }) => {
  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Insights",
      path: "/insights"
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  const featuredArticle =
    data.nodeLabsOverview.relationships.field_featured_labs_article;

  const filteredArticles = data.allNodeLabsArticle.edges.filter(edge => {
    return edge.node.id !== featuredArticle.id;
  });

  // Add the featured article to the start of the filteredArticles list
  filteredArticles.unshift({ node: featuredArticle });

  return (
    <Layout>
      <>
        <HelmetWrapper
          canonicalPath="/insights"
          title={data.nodeLabsOverview.title}
          description={data.nodeLabsOverview.field_meta_description}
          updatedAt={data.nodeLabsOverview.changed}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="header"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              gradient="orangePink"
              height="small"
            >
              <FullWidthColumnWrapper>
                <div className="top-content">
                  <div className="content">
                    <div className="heading-container">
                      <p className="heading">
                        {data.nodeLabsOverview.title}
                      </p>

                      <p className="into-text">
                        <Wysiwyg
                          type="formatted"
                          content={data.nodeLabsOverview.field_intro_text}
                        />
                      </p>

                      <Icon
                        className="icon"
                        icon="lightbulb"
                        size={4}
                      />
                    </div>

                    <HeroTags
                      initialTheme="dark"
                      featuredPath="/insights"
                      tags={data.allTaxonomyTermLabs.edges.map(tag => {
                        return {
                          name: tag.node.name,
                          path: tag.node.path.alias
                        };
                      })}
                    />
                  </div>
                </div>
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {data.nodeLabsOverview.title}
          </h1>

          <InsightsTeasersContainer className="content-container">
            {filteredArticles.slice(0, 1).map((post: INodeData, i) => {
              const featured: boolean = i === 0;
              const profile = getProfile(post.node.relationships.author);

              return (
                <FullWidthTeaser
                  key={post.node.id}
                  created={post.node.created}
                  path={post.node.path.alias}
                  title={post.node.title}
                  author={profile.field_full_name}
                  cover={post.node.relationships.field_cover_image}
                  alt={post.node.field_cover_image.alt}
                  featured={featured}
                  excerpt={post.node.field_labs_cover_summary.processed}
                  tags={post.node.relationships.field_labs_tags}
                />
              );
            })}

            {filteredArticles.slice(1, 11).map((post: INodeData) => {
              const profile = getProfile(post.node.relationships.author);

              return (
                <div
                  className="insights-teasers-wrapper"
                  key={post.node.id}
                >
                  <InsightsTeaser
                    path={post.node.path.alias}
                    title={post.node.title}
                    created={post.node.created}
                    author={profile.field_full_name}
                    cover={post.node.relationships.field_cover_image}
                    alt={post.node.field_cover_image.alt}
                    excerpt={post.node.field_labs_cover_summary.processed}
                    tags={post.node.relationships.field_labs_tags}
                  />

                  <div className="hr">
                    <MediumColumnWidthWrapper>
                      <span />
                    </MediumColumnWidthWrapper>
                  </div>
                </div>
              );
            })}
          </InsightsTeasersContainer>

          <aside>
            <SmallColumnWidthWrapper>
              <Pagination
                current={0}
                total={data.totalInsights.totalCount}
                limit={10}
                slug="/insights"
              />
            </SmallColumnWidthWrapper>
          </aside>
        </main>

        <footer role="contentinfo">
          <GetInTouch
            links={[
              {
                href: "/latest",
                text: "Latest"
              },
              {
                href: "/about-us",
                text: "Meet the team"
              }
            ]}
          />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export const query = graphql`
  query labsQuery($labsLimit: Int = 11, $tagLimit: Int = 9) {
    totalInsights: allNodeLabsArticle {
      totalCount
    }
    nodeLabsOverview {
      created
      changed
      title
      field_intro_text
      field_meta_description
      relationships {
        field_featured_labs_article {
          ...getLabsData
        }
      }
    }
    ...getAllLabs
    ...getLabsTags
  }
`;

export default Insights;
