import moment from "moment";
import React from "react";

import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { SmallColumnWidthWrapper } from "../confines-wrappers";
import DynamicImage from "../dynamic-image";
import { ISimpleTag } from "../full-width-teaser/full-width-teaser";
import Link from "../link";
import Wysiwyg from "../wysiwyg";

interface IInsightsTeaser {
  title: string;
  path: string;
  cover: ILocalFile<IFluid>;
  alt: string;
  author: string;
  excerpt: string;
  created: string;
  tags?: ISimpleTag[];
}

const InsightsTeaserContainer = styled(SmallColumnWidthWrapper)`
  transition: 0.2s ease-in-out;
  position: relative;

  .insights-teaser-wrapper {
    transition: 0.2s ease-in-out;
    position: relative;

    * {
      margin-bottom: 0;
    }

    .content {
      position: relative;
      padding-top: 74%;

      header {
        position: relative;
      }

      h2 {
        padding-top: 2.4rem;
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

        a {
          border-bottom: 0.2rem solid transparent;

          &:hover {
            border-bottom-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          }
        }
      }

      span {
        display: block;
      }

      .authored-on {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};
        letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
        text-transform: uppercase;
      }

      .authored-on,
      .authored-by,
      .tags {
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      }

      .authored-on,
      .authored-by {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "lightGrey"
  })};
      }

      .authored-by,
      .tags {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
      }

      .authored-by {
        padding-top: 1.4rem;
      }

      p {
        margin-bottom: 0;
        padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
      }

      .tags {
        margin: 0;
        padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
        padding-left: 0;
        list-style: none;
        color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColorLight"
    })};

        li {
          display: inline;

          &:nth-last-child(1n + 2) {
            &:after {
              content: ", ";
            }
          }
        }
      }
    }

    .image {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      a {
        display: block;
        width: 100%;
      }
    }
  }

  @media (min-width: 375px) {
    .insights-teaser-wrapper {
      margin: 0 auto;
      width: 33.5rem;
    }
  }

  @media (min-width: 600px) {
    .insights-teaser-wrapper {
      padding-left: 38%;
      width: 100%;

      .content {
        padding-top: 0;
      }

      .image {
        left: 0;
        transform: translateX(0);
        width: 32%;
      }
    }
  }

  @media (min-width: 768px) {
    .insights-teaser-wrapper {
      .content {
        h2 {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
        }

        .authored-on {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
        }

        .authored-by,
        .tags {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
        }
      }
    }
  }

  @media (min-width: 960px) {
    .insights-teaser-wrapper {
      .content {
        h2 {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
        }

        .authored-on {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
        }

        .authored-by,
        .tags {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
        }

        p {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
        }
      }
    }
  }
`;

const InsightsTeaser = ({
  author,
  title,
  cover,
  alt,
  created,
  excerpt,
  path,
  tags
}: IInsightsTeaser) => {
  const date = moment.utc(created).format("D MMM YYYY");

  return (
    <InsightsTeaserContainer className="insights-teaser-container">
      <article className="insights-teaser-wrapper">
        <div className="content">
          <header role="banner">
            <h2>
              <Link
                type="none"
                href={path}
              >
                {title}
              </Link>
            </h2>

            <span className="authored-on">
              <span className="off-screen">
                Published on
                {" "}
              </span>

              {date}

              <span className="off-screen">
                ,
                {" "}
              </span>
            </span>

            <span className="authored-by">
              By
              {" "}

              {author}

              <span className="off-screen">
                .
              </span>
            </span>
          </header>

          <Wysiwyg
            className="excerpt"
            type="plain"
            content={excerpt}
          />

          {tags && (
            <footer role="contentinfo">
              <ul className="tags">
                {tags.map((tag, i: number) => {
                  return (
                    <li key={i}>
                      <Link href={tag.path.alias}>
                        {tag.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </footer>
          )}
        </div>

        <div className="image">
          <Link
            type="none"
            href={path}
          >
            <DynamicImage
              data={cover}
              alt={alt}
            />
          </Link>
        </div>
      </article>
    </InsightsTeaserContainer>
  );
};

export default InsightsTeaser;
