import { graphql } from "gatsby";
import React from "react";

import {
  FullWidthColumnWrapper,
  MediumColumnWidthWrapper,
  SmallColumnWidthWrapper
} from "../components/confines-wrappers";
import Footer from "../components/footer";
import GetInTouch from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HeroTags from "../components/hero-tags";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import {
  IBreadCrumb,
  IBreadCrumbSchema
} from "../components/html-schema-snippets/breadcrumb-schema";
import Icon from "../components/icon";
import InsightsTeaser from "../components/insights-teaser";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import Pagination from "../components/pagination";
import Wysiwyg from "../components/wysiwyg";
import {
  IInsightsArticleData,
  InsightsTeasersContainer,
  PageTopCustom
} from "../pages/insights";
import getProfile from "./utils/getProfile";

export interface IAllTaxonomyTermInsights {
  edges: ITaxonomyTermInsights[];
}

interface ITaxonomyTermInsights {
  node: {
    name: string;
    path: {
      alias: string;
    };
  };
}

interface IPageContext {
  skip: number;
  limit: number;
  current: number;
  name: string;
  slug: string;
  total: number;
}

interface INodeData {
  node: IInsightsArticleData;
}

interface IInsightsTagsData {
  nodeLabsOverview: {
    created: string;
    changed: string;
    title: string;
    field_intro_text: string;
    field_meta_description: string;
  };
  allNodeLabsArticle: {
    edges: INodeData[];
  } | null;
  allTaxonomyTermLabs: IAllTaxonomyTermInsights;
}

const InsightsTags = ({
  data,
  pageContext
}: {
  data: IInsightsTagsData;
  pageContext: IPageContext;
}) => {
  const paginationPage = pageContext.current + 1;

  const breadcrumbs: IBreadCrumb[] = [
    {
      name: "Insights",
      path: "/insights"
    }
  ];

  let insightsNodes: INodeData[] = [];
  let insightsArticles: IInsightsArticleData[] = [];
  let schemaJson: IBreadCrumbSchema[] = [];

  if (paginationPage > 1) {
    breadcrumbs.push({
      name: `Page ${paginationPage}`,
      path: `${pageContext.slug}/${paginationPage}`
    });
  }

  // Now set the schema JSON object
  schemaJson = [ breadCrumbSchema(breadcrumbs) ];

  // If there is allNodeLabsArticle, set the insightsNodes variable
  if (data.allNodeLabsArticle) {
    insightsNodes = data.allNodeLabsArticle.edges;

    insightsArticles = insightsNodes.map(projectNode => {
      return projectNode.node;
    });
  }

  return (
    <Layout className="insights insights-by-tag">
      <>
        <HelmetWrapper
          title={`${pageContext.name} page ${paginationPage}`}
          description={data.nodeLabsOverview.field_meta_description}
          updatedAt={insightsArticles[ 0 ] ? insightsArticles[ 0 ].changed : ""}
          canonicalPath={pageContext.slug}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="header"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              gradient="orangePink"
              height="small"
            >
              <FullWidthColumnWrapper>
                <aside className="top-content">
                  <div className="content">
                    <div className="heading-container">
                      <p className="heading">
                        {data.nodeLabsOverview.title}
                      </p>

                      <p className="into-text">
                        <Wysiwyg
                          type="formatted"
                          content={data.nodeLabsOverview.field_intro_text}
                        />
                      </p>

                      <Icon
                        className="icon"
                        icon="lightbulb"
                        size={4}
                      />
                    </div>

                    <HeroTags
                      initialTheme="dark"
                      featuredPath="/insights"
                      tags={data.allTaxonomyTermLabs.edges.map(tag => {
                        return {
                          name: tag.node.name,
                          path: tag.node.path.alias
                        };
                      })}
                    />
                  </div>
                </aside>
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {`${pageContext.name} articles`}
          </h1>

          <InsightsTeasersContainer className="content-container">
            {insightsArticles.map((post: IInsightsArticleData) => {
              const profile = getProfile(post.relationships.author);

              return (
                <div
                  className="insights-teasers-wrapper"
                  key={post.id}
                >
                  <InsightsTeaser
                    path={post.path.alias}
                    title={post.title}
                    created={post.created}
                    author={profile.field_full_name}
                    cover={post.relationships.field_cover_image}
                    alt={post.field_cover_image.alt}
                    excerpt={post.field_labs_cover_summary.processed}
                    tags={post.relationships.field_labs_tags}
                  />

                  <div className="hr">
                    <MediumColumnWidthWrapper>
                      <span />
                    </MediumColumnWidthWrapper>
                  </div>
                </div>
              );
            })}
          </InsightsTeasersContainer>

          <aside>
            <SmallColumnWidthWrapper>
              <Pagination
                current={pageContext.current}
                total={pageContext.total}
                limit={pageContext.limit}
                slug={pageContext.slug}
              />
            </SmallColumnWidthWrapper>
          </aside>
        </main>

        <footer role="contentinfo">
          <GetInTouch />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default InsightsTags;

export const labsTagsQuery = graphql`
  query($skip: Int = 0, $limit: Int = 10, $tagLimit: Int = 9) {
    nodeLabsOverview {
      created
      changed
      title
      field_intro_text
      field_meta_description
    }
    allNodeLabsArticle(
      limit: $limit
      skip: $skip
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          ...getLabsData
        }
      }
    }
    ...getLabsTags
  }
`;
